import { LineChart, Card, Title } from "@tremor/react";
import { useState, useEffect } from "react";
import { RangeSelector } from "./RangeSelector";

export const Chart = ({ chartdata, title, categories }) => {
  const [range, setRange] = useState("30");
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    try {
      const combinedData = [];
      const fbmData = chartdata.FBM_prices[`days_${range}`];
      const fbaData = chartdata.FBA_prices[`days_${range}`];

      fbmData.forEach((fbmEntry, index) => {
        const date = new Date(fbmEntry.time * 1000);
        const formattedDate = `${date.toLocaleString("default", {
          month: "short",
        })} ${date.getDate()}`;

        const fbaEntry = fbaData[index];
        console.log("fbaEntry", fbaEntry);
        const entryObject = {
          date: formattedDate,
          FBM: fbmEntry.price / 100,
          FBA: fbaEntry ? fbaEntry.value / 100 : null, // Handle case where FBA data might be missing
        };

        combinedData.push(entryObject);
      });
      console.log(combinedData, "combinedData");
      setGraphData(combinedData);
    } catch (error) {
      console.log(error);
    }
  }, [range]);

  const valueFormatter = function (number) {
    if (number >= 0) {
      return "$ " + new Intl.NumberFormat("us").format(number).toString();
    } else return "";
  };

  return (
    <div className="mt-5 pt-2 pb-4 px-5 border-[1px] rounded-lg space-y-3">
      <p className="text-Inter text-gray-800 font-semibold text-xl">Chart</p>

      <Card>
        <Title className="mb-4">{title}</Title>
        <RangeSelector range={range} setRange={setRange} />
        <LineChart
          className="h-72 mt-4"
          data={graphData}
          index="date"
          categories={categories}
          colors={["indigo", "cyan", "green"]} // Added another color for FBA
          valueFormatter={valueFormatter}
        />
      </Card>
    </div>
  );
};
