import authenticatedInstance from "./AxiosInstance/authenticated";

export const fetchListsAndProducts = (userId) => async (dispatch) => {
  try {
    const resp = await authenticatedInstance.post("/tier/retrieveTier", { userId: userId });


    //filter null values from the response
    const filteredResp = resp.data.data.filter((plan) => plan !== null);

    const modifiedPlans = filteredResp.map((plan) => {
      if (plan) {
        const isActive = plan.hasOwnProperty("active") ? plan.active : false;

        return {
          id: plan.tierId || plan.id,
          name: plan.name || plan.tier?.name, // Some plans may not have a name, but a tier with a name
          active: isActive,
          amount: plan.amount || plan.tier?.amount, // Some plans may not have an amount, but a tier with an amount
          stripePlanId: plan.stripePlanId || plan.tier?.stripePlanId,
          listId: plan.listId,
          billingCycle: resp.data.billingCycle,
          slotsAvailable: plan.slotsAvailable,
          // Similar case for stripePlanId
        };
      }
    });


    dispatch({ type: "SET_LISTS", payload: modifiedPlans });


  } catch (error) {
    console.log(error, "Error in signin");
  }
};

export const refreshProductsJson = async () => {
  try {
    const { status } = await authenticatedInstance.get("/product/updateProductJson");
    return status;
  } catch (error) {
    console.log(error);
    throw error;
  }
};