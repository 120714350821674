import { Toaster } from "sonner";
import PrivateRoute from "./components/PrivateRoute";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Authentication from "./pages/Authentication/Authentication.js";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ResetPassword from "./pages/Authentication/ResetPassword";
import Index from "./pages/Index";
import PricingPage from "./components/stripePricingTable";
import SignupPricingTable from "./components/SignupPricingTable";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHING_KEY)
function App() {
  console.log(process.env.REACT_APP_BASE_URL);

  const [clientSecret, setClientSecret] = useState("");



  const appearance = {
    theme: 'stripe',
  };
  const options = {
    mode: 'setup',
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {/*...*/ },
  };


  return (
    <>
      <Elements stripe={stripePromise}>
        <Toaster position="top-right" richColors closeButton />
        <Routes>
          <Route path="/" element={<Navigate to="/signup" />} />
          <Route path="/signup" element={
            <Authentication />

          } />

          <Route path="/signin" element={<Authentication />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/plans" element={<SignupPricingTable />} />

          {/* <Route element={<PrivateRoute />} > */}
          <Route path="/*" element={<Index />} />
          {/* </Route> */}
        </Routes>
      </Elements>
    </>
  );
}

export default App;
