import React from "react";
import { pink, grey } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "sonner";
export const Rating = ({ rating, favouriteStatus, productId }) => {
  const [isFavourite, setIsFavourite] = React.useState(favouriteStatus || false);
  const roundedRating = Math.floor(rating.rating);
  const [loading, setLoading] = React.useState(false);

  const handleFavourite = async () => {
    // console.log(productId)
    setLoading(true);
    authenticatedInstance
      .post("product//updateFavouriteStatus", {
        productId: productId,
      })
      .then(() => {
        setIsFavourite(!isFavourite);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filledStars = Array.from({ length: roundedRating }, (_, index) => (
    <svg
      className="w-4 h-4 text-yellow-300 mr-1"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 22 20"
      key={index}
    >
      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
    </svg>
  ));
  const emptyStars = Array.from({ length: 5 - roundedRating }, (_, index) => (
    <svg
      className="w-4 h-4 text-gray-300 mr-1 dark:text-gray-500"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 22 20"
      key={index}
    >
      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
    </svg>
  ));
  return (
    <div className="flex justify-between align-middle">
      <div>
        <div className="flex items-center mt-2 leading-4">
          {filledStars}
          {emptyStars}
          <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">{rating.rating} out of 5</p>
        </div>
        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">{rating.ratings_total} global ratings</p>
      </div>
      <div
        onClick={() => {
          handleFavourite();
        }}
        className="mt-3 mr-3"
      >
        {!loading ? (
          <FavoriteIcon sx={{ color: isFavourite ? pink[500] : grey[400] }} />
        ) : (
          <CircularProgress sx={{ maxHeight: "20px", maxWidth: "20px", marginTop: "5px" }} />
        )}
      </div>
    </div>
  );
};
