import React, { useState, useEffect, useRef } from "react";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Dropdown, Button, Space } from "antd";
import { fetchListsAndProducts } from "../../redux/actions/list";

const DropDown = ({ productId, status }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(
    status
      ? {
          item: status,
          color:
            status === "In Stock"
              ? "#A8DF8E"
              : status === "Out of Stock"
              ? "#FFFBC1"
              : status === "Sent to Amazon"
              ? "#A0E9FF"
              : "#E5D4FF",
        }
      : { item: "In Stock", color: "#A8DF8E" }
  );
  const dropdownRef = useRef(null);
  const { userId } = useSelector((state) => state.auth.user);
  // const options = ["In Stock", "Shortlisted", "Contacted", "Ordered"];

  const items = [
    {
      key: "1",
      label: (
        <Button
          style={{ backgroundColor: "#A8DF8E" }}
          className={`text-grey`}
          onClick={() => handleItemClick({ item: "In Stock", color: "#A8DF8E" })}
        >
          In Stock
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          style={{ backgroundColor: "#FFFBC1" }}
          className={`text-grey`}
          onClick={() => handleItemClick({ item: "Out of Stock", color: "#FFFBC1" })}
        >
          Out of Stock
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          style={{ backgroundColor: "#A0E9FF" }}
          className={`text-grey`}
          onClick={() => handleItemClick({ item: "Sent to Amazon", color: "#A0E9FF" })}
        >
          Sent to Amazon
        </Button>
      ),
    },
    {
      key: "4",
      label: (
        <Button
          style={{ backgroundColor: "#E5D4FF" }}
          className={`text-grey`}
          onClick={() => handleItemClick({ item: "Ordered", color: "#E5D4FF" })}
        >
          Ordered
        </Button>
      ),
    },
  ];

  const handleItemClick = (item) => {
    console.log("productId", productId);
    try {
      console.log(item);
      setSelectedItem(item);
      authenticatedInstance.post("/product/changeProductStatus", {
        userId: userId,
        productId: productId,
        status: item.item,
      });
      toast.success(`Product status changed to ${item.item}`);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(fetchListsAndProducts(userId));
      setIsOpen(false);
    }
  };

  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="bottom"
      arrow={{
        pointAtCenter: true,
      }}
    >
      <Button style={{ backgroundColor: selectedItem.color }} className={`text-grey`}>
        {" "}
        <Space>
          {selectedItem.item}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>

    // <div className="relative inline-block text-left" ref={dropdownRef}>
    //   <button
    //     id="dropdownDefaultButton"
    //     data-dropdown-toggle="dropdown"
    //     className={`text-white bg-${selectedItem.color}-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-800`}
    //     type="button"
    //     onMouseEnter={() => setIsOpen(!isOpen)}
    //   >
    //     {selectedItem.item || "Dropdown button"}
    //     <svg
    //       className="w-2.5 h-2.5 ms-3"
    //       aria-hidden="true"
    //       xmlns="http://www.w3.org/2000/svg"
    //       fill="none"
    //       viewBox="0 0 10 6"
    //     >
    //       <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
    //     </svg>
    //   </button>

    //   {/* Dropdown menu */}
    //   {isOpen && (
    //     <div className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-500">
    //       <ul className="py-2 text-sm text-gray-500 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
    //         {options.map((item, index) => (
    //           <li key={index}>
    //             <span
    //               className={`block px-4 bg-${item.color}-300 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer`}
    //               onClick={() => handleItemClick(item)}
    //             >
    //               {item.item}
    //             </span>
    //           </li>
    //         ))}
    //       </ul>
    //     </div>
    //   )}
    // </div>
  );
};

export default DropDown;
