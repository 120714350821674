import React from "react";

export const RangeSelector = ({ range, setRange, removeCurrent }) => {
  return (
    <div className="flex items-center gap-x-2">
      {removeCurrent ? null : (
        <p
          className={`text-Inter cursor-pointer text-purple-700 ${
            range === "current" ? "bg-purple-200" : ""
          } text-sm py-1 px-2 rounded-md`}
          onClick={() => setRange("current")}
        >
          Current
        </p>
      )}

      <p
        className={`text-Inter cursor-pointer text-purple-700 ${
          range === "30" ? "bg-purple-200" : ""
        } text-sm py-1 px-2 rounded-md`}
        onClick={() => setRange("30")}
      >
        Last 30 days
      </p>
      <p
        className={`text-Inter cursor-pointer text-purple-700 ${
          range === "90" ? "bg-purple-200" : ""
        } text-sm py-1 px-2 rounded-md`}
        onClick={() => setRange("90")}
      >
        Last 90 days
      </p>
      <p
        className={`text-Inter cursor-pointer text-purple-700 ${
          range === "180" ? "bg-purple-200" : ""
        } text-sm py-1 px-2 rounded-md`}
        onClick={() => setRange("180")}
      >
        Last 180 days
      </p>
      {/* <p
        className={`text-Inter cursor-pointer text-purple-700 ${
          range === "All" ? "bg-purple-200" : ""
        } text-sm py-1 px-2 rounded-md`}
        onClick={() => setRange("All")}
      >
        All
      </p> */}
    </div>
  );
};
