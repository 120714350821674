export const TimeAndShipping = ({ processingTime, details }) => {
  return (
    <>
      <div className="mt-4 px-2 space-y-3">
        <p className="text-Inter text-gray-800 font-semibold text-xl">Processing Time</p>
        <p className="text-Inter text-secondary text-sm">{processingTime} Business days</p>
      </div>


      {details.length > 0 && 
      <>
        <hr className="bg-gray-200 h-[1.15px] mt-3 px-2" />
      <div className="mt-4 px-2 space-y-2">
        <p className="text-Inter text-gray-800 font-semibold text-xl">Shipping Time</p>
        {details.map((shipping, index) => (
          <div key={index}>
            <p className="text-Inter text-gray-700 text-lg font-semibold leading-5">{shipping.region}</p>
            <div className={`flex item-center justify-between`}>
              <p className="text-Inter text-gray-600 text-sm">
                {shipping.min_days + "-" + shipping.max_days} business days
              </p>
              <p className="text-Inter text-gray-900 font-semibold">${shipping.cost}</p>
            </div>
          </div>
        ))}
      </div>
      </>}
    </>
  );
};
