import React, { useEffect, useState } from "react";
import { RangeSelector } from "./RangeSelector";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";

export const KeepaChart = ({ asin }) => {
  const [keepsChart, setKeepaChart] = useState("");
  const [range, setRange] = useState("30");

  useEffect(() => {
    getKeepaChart();
  }, [range]);

  const getKeepaChart = async () => {
    try {
      const keepaChart = await authenticatedInstance.post("/product/getKeepaChart", {
        asin: asin,
        range: range === "All" ? 365 : range,
      }, { responseType: 'arraybuffer' });
      const arrayBuffer = keepaChart.data;
      const blob = new Blob([arrayBuffer]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        setKeepaChart(base64data);
      };
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="mt-5 pt-2 pb-4 px-5 border-[1px] rounded-lg space-y-3">
      <p className="text-Inter text-gray-800 font-semibold text-xl">Keepa Chart</p>
      <div className="mt-3">
        <img alt="keepachart" style={{ width: "-webkit-fill-available" }} src={keepsChart} />
        <RangeSelector range={range} setRange={setRange} removeCurrent={true} />
      </div>
    </div>
  );
};
