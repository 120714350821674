import { useState, useEffect } from "react";
import DropDown from "./DropDown";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { Col, InputNumber, Row, Slider, Typography, Collapse } from "antd";

export const Overview = ({ overview, productId, productStatus, product, actualPrice, profitCalculator }) => {
  const [est, setEST] = useState("");
  // console.log(product, overview, "wow");
  const [price, setPrice] = useState({
    costPrice: parseFloat(actualPrice.costPrice),
    salePrice: parseFloat(actualPrice.salePrice),
  });
  const [totalFees, setTotalFees] = useState(profitCalculator.total_fees);
  const [profit, setProfit] = useState(parseInt(0));
  const [maxCost, setMaxCost] = useState(parseInt(profitCalculator?.max_cost));
  const [roi, setRoi] = useState(parseFloat(profitCalculator?.roi.replace("%", "")));
  const getEST = async () => {
    try {
      // const getEST = await fetch("https://vm1.getionio.com/api/getsales", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${process.env.REACT_APP_KEEPA_TOKEN}`,
      //   },
      //   body: JSON.stringify({
      //     asin: product.asin,
      //     bsr: overview.BSR.BSR,
      //     category: product.basicInfo.category,
      //   }),
      // });
      const getEST = await authenticatedInstance.post("/product/getSales", {
        asin: product.asin,
        bsr: overview.BSR.BSR,
        category: product.basicInfo.category,
      });
      const { sales_estimation } = getEST.data;
      setEST(sales_estimation.monthly_sales);
      return sales_estimation.monthly_sales;
    } catch (error) {
      console.error(error);
      setEST(-1);
      return -1;
    }
  };

  useEffect(() => {
    getEST();
  }, []);


  useEffect(() => {
    calculateROIProfit();
  }, [price.costPrice, price.salePrice, totalFees.total]);

  const calculateROIProfit = () => {
    // roi and profit
    let final_sale_price = price.salePrice - parseFloat(totalFees.total);
    let total_cost_price = price.costPrice + parseFloat(totalFees.total);
    let roi = parseFloat((((final_sale_price - price.costPrice) / price.costPrice) * 100).toFixed(2))
    setRoi(roi);

    let prof = (price.salePrice - total_cost_price).toFixed(2);
    setProfit(prof);

    // [profit margin]
    let prof_margin = ((prof / price.salePrice) * 100).toFixed(2);
    
    let max_cost = ((price.salePrice - parseFloat(totalFees.total)) / (1 + roi / 100)).toFixed(2);
    setMaxCost(max_cost);
  };

  return (
    <div className="mt-5 pt-2 pb-4 px-5 border-[1px] rounded-lg">
      <p className="text-Inter text-gray-800 font-semibold text-xl">Overview</p>

      <div className="grid grid-cols-3 gap-x-5 mt-2">
        <div className="border-[1px] border-gray-200 rounded-lg col-span-1 space-y-0.5 shadow-sm px-6 py-4">
          <p className="text-center text-Inter text-md ">BSR</p>
          {overview.BSR.BSR === -1 ? (
            <p className="text-center text-Inter text-md text-green-600 font-semibold">-</p>
          ) : (
            <p className="text-center text-Inter text-md text-green-600 font-semibold">
              {overview.BSR.BSR.toLocaleString()}{" "}
              {overview?.BSR.percentage != null ? (
                overview.BSR.percentage.includes("-") ? (
                  <span className="text-red-600">({overview.BSR.percentage})</span>
                ) : (
                  <span className="text-green-600">({overview.BSR.percentage})</span>
                )
              ) : (
                ""
              )}
            </p>
          )}
        </div>

        <div className="border-[1px] border-gray-200 rounded-lg col-span-1 space-y-0.5 shadow-sm px-6 py-4">
          <p className="text-center text-Inter text-md ">Est. Sales </p>
          <p className="text-center text-Inter text-md text-yellow-600 font-semibold">
            {est !== -1 ? est + "/Mo" : "-"}{" "}
          </p>
        </div>

        <div className="border-[1px] border-gray-200 rounded-lg col-span-1 space-y-0.5 shadow-sm px-6 py-4">
          <p className="text-center text-Inter text-md ">Max Cost</p>
          <p className="text-center text-Inter text-md text-secondary font-semibold">${maxCost}</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-5 mt-4">
        <div className="border-[1px] border-gray-200 bg-[#F7F7F9] rounded-lg col-span-1 space-y-1.5 shadow-sm px-6 py-4">
          <p className="text-center text-Inter text-md ">Cost Price</p>
          <div className="flex items-center justify-between rounded-lg bg-white border-2">
            {/* <p>$</p>
            <p className="text-center text-Inter text-md text-secondary font-semibold">{Number(actualPrice.costPrice)}</p> */}
            <InputNumber
              className="w-full text-right"
              //   type="number"
              placeholder="Cost Price"
              prefix="$"
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              value={price.costPrice}
              onChange={(value) => {
                setPrice({ ...price, costPrice: value });
              }}
            />
          </div>
        </div>

        <div className="border-[1px] border-gray-200 bg-[#F7F7F9] rounded-lg col-span-1 space-y-1.5 shadow-sm px-6 py-4">
          <p className="text-center text-Inter text-md ">Sale Price</p>
          <div className="flex items-center justify-between rounded-lg bg-white border-2">
            <InputNumber
              className="w-full"
              placeholder="Sales Price"
              prefix="$"
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              value={price.salePrice}
              onChange={(value) => {
                setPrice({ ...price, salePrice: value });
              }}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-x-5 mt-4">
        <div className="border-[1px] border-gray-200 rounded-lg col-span-1 space-y-0.5 shadow-sm px-6 py-4">
          <p className="text-center text-Inter text-md ">Profit</p>
          <p className="text-center text-Inter text-md text-secondary font-semibold">${profit}</p>
        </div>

        <div className="border-[1px] border-gray-200 rounded-lg col-span-1 space-y-0.5 shadow-sm px-6 py-4">
          <p className="text-center text-Inter text-md ">ROI</p>
          <p className="text-center text-Inter text-md text-secondary font-semibold">{roi}</p>
        </div>

        <div className="flex items-center flex-col border-[1px] border-gray-200 rounded-lg col-span-1 space-y-0.5 shadow-sm px-6 py-4">
          <p className="text-center text-Inter text-md ">Status</p>
          <DropDown productId={productId} status={productStatus} />
        </div>

        {/* <p className="text-center text-Inter text-md text-green-600 bg-green-100 rounded-lg mt-0.5 px-3 w-fit mx-auto">
            Open
          </p> */}
      </div>
    </div>
  );
};
