import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { fetchListsAndProducts } from "../../redux/actions/list";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import React from "react";
import { upgradeSubscription, unsubscribe } from "../Subscription/CreateSubscription";
import { Spin, Button } from "antd";
import { CloseSquareOutlined } from "@ant-design/icons";
import PricingConfirmModal from "../PricingConfirmModal";
import { useNavigate } from "react-router-dom";
function StatusLabel({ active, setModal, slotsAvailable, amount, setUnsubscribeModal, modal, onUpgrade }) {
  switch (active) {
    case true:
      return (
        <div className="flex  flex-col items-center">
          <div className="flex items-center bg-green-100 py-1 px-2.5 rounded-2xl gap-x-1 cursor-auto">
            <p className="bg-green-600 rounded-full !h-2 !w-2"></p>
            <p className="font-[500] font-Inter text-md text-green-600">Active</p>
          </div>
        </div>
        // <Tippy content={params.row?.status} arrow={true}>

        /* </Tippy> */
      );

    case false:
      return (
        <div className="flex flex-col items-center">
          {/* <p className="bg-[#ECFDF3] py-1 px-2.5  rounded-2xl gap-x-1  font-[500] font-Inter text-sm text-[#027A48]">
            {slotsAvailable ? slotsAvailable : process.env.REACT_APP_DEFAULT_LIST_SPACE} Slots Available
          </p> */}
          <Button
            loading={modal}
            type="primary"
            style={{ backgroundColor: "#1677ff" }}
            className="text-sm font-semibold text-[white] "
            onClick={() => {
              onUpgrade();
            }}
          >
            Upgrade
          </Button>
        </div>
        // <Tippy content={params.row?.status} arrow={true}>

        // {/* </Tippy> */ }
      );
    default:
      return (
        <div className="flex flex-col items-center gap-5">
          <div className="flex items-center bg-green-100 py-1 px-2.5 rounded-2xl gap-x-1 cursor-auto">
            <p className="bg-green-600 rounded-full !h-2 !w-2"></p>
            <p className="font-[500] font-Inter text-md text-green-600">Active</p>
          </div>
          <button
            type="button"
            onClick={() => setUnsubscribeModal(true)}
            className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm px-2 py-1 text-center  dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
          >
            Upgrade
          </button>
        </div>
      );
  }
}

export const StatusCard = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [modal, setModal] = React.useState(false);
  const [unsubscribeModal, setUnsubscribeModal] = React.useState(false);
  const navigate = useNavigate();
  console.log(props, "props");

  // const { id, title, active, slotsAvailable, amount, stripePlanId, setLoader, billingCycle } = props;
  // async function unsubscribeList() {
  //   try {
  //     setLoader(true);
  //     const { status } = await unsubscribe(id, stripePlanId, user.userId);
  //     console.log(status, "status");
  //     if (status === 200) {
  //       toast.success("unsubscribed successfully!");
  //       window.location.reload();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("something went wrong!");
  //   } finally {
  //     setLoader(false);
  //   }
  // }

  // async function createSub() {
  //   try {
  //     const { url } = await upgradeSubscription(user.userId, user.userEmail, id, stripePlanId, user.stripeCustomerId);

  //     toast.success("Go to checkout!");
  //     const newWindow = window.open(url, "_self", "noopener,noreferrer");
  //     if (newWindow) newWindow.opener = null;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  return (
    <>
      <div className={`rounded-lg shadow-sm w-full max-w-lg h-full p-6 ${props?.planType === 'Annual' ? `border-[2px] border-primary/50 bg-primary/10` : `border-[1px] border-gray-200`}`}>
        <p className="text-lg text-gray-600 whitespace-pre-wrap capitalize  leading-7 text-center font-semibold text-Inter">
          {/* {props?.planName} */}

          {props?.planType === "Annual" ? "annual" : "monthly"}
          {/* <span className="font-light block text-xs font-gray-400 "> */}
          {/* </span> */}

        </p>

        <div className="mt-2.5 space-y-3 mb-4">
          {props?.features.map((feature, key) => (
            <p key={key} className={`flex items-center text-[#8181ac] text-Inter text-sm`}>
              <span className={`w-4 h-4 mr-2 inline-flex items-center justify-center bg-orange-200 text-white rounded-full flex-shrink-0`}>
                {/* Your SVG icon here */}
              </span>
              {feature}
            </p>
          ))}
        </div>

        <div className={`w-fit mx-auto pt-4 ${props?.planType != "Annual" && `mt-11`}`}>
          <StatusLabel
            active={props.currentPlanId === props.planId}
            setModal={setModal}
            amount={props.planAmount}
            setUnsubscribeModal={setUnsubscribeModal}
            unsubscribeModal={unsubscribeModal}
            modal={modal}
            onUpgrade={() => {
              navigate(`/settings`);
              // try {
              //   createSub();
              //   // this will trigger after payment
              //   // authenticatedInstance.post("/list/addUserToList", { listId: id });
              //   // dispatch(fetchListsAndProducts());

              //   // toast.success("Joined list successfully!");
              // } catch (error) {
              //   console.log(error, "Error in joining list");
              //   toast.error("Something went wrong!");
              // } finally {
              //   setModal(false);
              // }
            }}
          />
        </div>
      </div>
      {modal && (
        <div
          tabIndex={-1}
          className="flex justify-center items-center fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
        >
          <div
            className="px-8 py-6 w-[30%] bg-[#FFFFFF] rounded-[10px]"
            style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}
          >
            <div className="mb-5">
              <div className="font-semibold text-3xl text-[#111928] mb-1">Upgrade Tier</div>
              <div className="font-normal text-[#6B7280]">
                Are you sure you want to buy {title} Tier for ${amount}?
              </div>
            </div>
            <div className="flex justify-end mt-12 gap-3">
              <button
                onClick={() => {
                  setModal(false);
                }}
                style={{
                  borderRadius: "12px",
                  padding: "9px 17px 9px 17px",
                  border: "1px solid #D1D5DB",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100px",
                }}
              >
                <div className="text-sm font-semibold text-[#111928]">Cancel</div>
              </button>
              <button
                onClick={() => {
                  try {
                    createSub();
                    // this will trigger after payment
                    // authenticatedInstance.post("/list/addUserToList", { listId: id });
                    // dispatch(fetchListsAndProducts());

                    // toast.success("Joined list successfully!");
                  } catch (error) {
                    console.log(error, "Error in joining list");
                    toast.error("Something went wrong!");
                  } finally {
                    setModal(false);
                  }
                }}
                style={{
                  borderRadius: "12px",
                  padding: "9px 17px 9px 17px",
                  border: "1px solid #1C64F2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#1C64F2",
                  width: "100px",
                }}
              >
                <svg
                  className="mr-3"
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.7931 7.50003L12.9396 8.35358L8.65013 12.643C8.56064 12.737 8.51118 12.8622 8.51231 12.9921C8.51345 13.1232 8.56603 13.2486 8.65874 13.3413C8.75144 13.434 8.87685 13.4866 9.00794 13.4877C9.13783 13.4889 9.26301 13.4394 9.35703 13.3499L15.3534 7.35353C15.3534 7.35351 15.3534 7.35349 15.3534 7.35348C15.4471 7.25972 15.4998 7.13259 15.4998 7.00003C15.4998 6.86747 15.4471 6.74034 15.3534 6.64658L13.7931 7.50003ZM13.7931 7.50003H12.586H1C0.867392 7.50003 0.740215 7.44735 0.646446 7.35358C0.552678 7.25982 0.5 7.13264 0.5 7.00003C0.5 6.86742 0.552678 6.74025 0.646446 6.64648C0.740215 6.55271 0.867392 6.50003 1 6.50003H12.586H13.7931L12.9396 5.64648L8.64661 1.35353C8.64659 1.35351 8.64657 1.35349 8.64655 1.35348C8.55285 1.25972 8.50021 1.13259 8.50021 1.00003C8.50021 0.867498 8.55283 0.740391 8.6465 0.646637C8.74026 0.552902 8.86742 0.500244 9 0.500244C9.13256 0.500244 9.25969 0.552882 9.35345 0.646584C9.35346 0.646602 9.35348 0.646619 9.3535 0.646637L15.3534 6.64653L13.7931 7.50003Z"
                    fill="white"
                    stroke="white"
                  />
                </svg>
                <div className="text-sm font-semibold text-[white]">Yes</div>
              </button>
            </div>
          </div>
        </div>
        // <PricingConfirmModal planName={title} tierId={id} setModal={setModal}/>
      )}

      {unsubscribeModal && (
        <div
          tabIndex={-1}
          className="flex justify-center items-center fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
        >
          <div
            className="px-8 py-6 w-[30%] bg-[#FFFFFF] rounded-[10px]"
            style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}
          >
            <div className="mb-5">
              <div className="font-semibold text-3xl text-[#111928] mb-1">Unsubscribe List</div>
              <div className="font-normal text-[#6B7280]">
                Are you sure you want to cancel subscription to {title} ?
              </div>
            </div>
            <div className="flex justify-end mt-12 gap-3">
              <button
                onClick={() => {
                  setUnsubscribeModal(false);
                }}
                style={{
                  borderRadius: "12px",
                  padding: "9px 17px 9px 17px",
                  border: "1px solid #D1D5DB",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100px",
                }}
              >
                <div className="text-sm font-semibold text-[#111928]">Cancel</div>
              </button>
              <button
                onClick={() => {
                  try {
                    unsubscribeList();
                  } catch (error) {
                    console.log(error, "Error in unsubscribe list");
                    toast.error("Something went wrong!");
                  } finally {
                    setUnsubscribeModal(false);
                  }
                }}
                style={{
                  borderRadius: "12px",
                  padding: "9px 17px 9px 17px",
                  border: "1px solid #c2320a",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#c2320a",
                  width: "100px",
                }}
              >
                <CloseSquareOutlined className="mr-3" style={{ fontSize: "1.5rem", color: "white" }} />
                {/* <svg
                  className="mr-3"
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.7931 7.50003L12.9396 8.35358L8.65013 12.643C8.56064 12.737 8.51118 12.8622 8.51231 12.9921C8.51345 13.1232 8.56603 13.2486 8.65874 13.3413C8.75144 13.434 8.87685 13.4866 9.00794 13.4877C9.13783 13.4889 9.26301 13.4394 9.35703 13.3499L15.3534 7.35353C15.3534 7.35351 15.3534 7.35349 15.3534 7.35348C15.4471 7.25972 15.4998 7.13259 15.4998 7.00003C15.4998 6.86747 15.4471 6.74034 15.3534 6.64658L13.7931 7.50003ZM13.7931 7.50003H12.586H1C0.867392 7.50003 0.740215 7.44735 0.646446 7.35358C0.552678 7.25982 0.5 7.13264 0.5 7.00003C0.5 6.86742 0.552678 6.74025 0.646446 6.64648C0.740215 6.55271 0.867392 6.50003 1 6.50003H12.586H13.7931L12.9396 5.64648L8.64661 1.35353C8.64659 1.35351 8.64657 1.35349 8.64655 1.35348C8.55285 1.25972 8.50021 1.13259 8.50021 1.00003C8.50021 0.867498 8.55283 0.740391 8.6465 0.646637C8.74026 0.552902 8.86742 0.500244 9 0.500244C9.13256 0.500244 9.25969 0.552882 9.35345 0.646584C9.35346 0.646602 9.35348 0.646619 9.3535 0.646637L15.3534 6.64653L13.7931 7.50003Z"
                    fill="white"
                    stroke="white"
                  />
                </svg> */}
                <div className="text-sm font-semibold text-[white]">Yes</div>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(StatusCard);
