const initState = {
  videos: [],
  pdfs: [],
  docs: [],
  csvs: [],
  loading: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_VIDEOS":
      return {
        ...state,
        videos: action.payload,
      };
    case "SET_PDFS":
      return {
        ...state,
        pdfs: action.payload,
      };
    case "SET_DOCS":
      return {
        ...state,
        docs: action.payload,
      };
    case "SET_CSVS":
      return {
        ...state,
        csvs: action.payload,
      };
    case "SET_LOADING_TRUE":
      return {
        ...state,
        loading: true,
      };
    case "SET_LOADING_FALSE":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
