export const ProductDescription = ({ description, dimensions }) => {
  const sentences = description.split(". ");

  const paragraphs = [];
  for (let i = 0; i < sentences.length; i += 2) {
    let paragraph = sentences.slice(i, i + 2).join(".");
    paragraph = paragraph + ".";
    paragraphs.push(paragraph);
  }

  const jsxFormattedDescription = paragraphs.map((paragraph, index) => (
    <p key={index} className="text-[15.4px] text-[#667085] text-Inter">
      {paragraph}
    </p>
  ));

  return (
    <div className="mt-8">
      <p className="text-Inter text-gray-800 font-semibold text-xl">Product Description</p>

      <div className="space-y-1.5 mt-2.5">
        {jsxFormattedDescription}
        <p className="text-[15.4px]  font-semibold text-[#667085] text-Inter">Specifications:</p>
        <table>
          <thead>
            <tr>
              <th className="border p-2 text-[15.4px] text-[#667085] text-Inter">Length</th>
              <th className="border p-2 text-[15.4px] text-[#667085] text-Inter">Width</th>
              <th className="border p-2 text-[15.4px] text-[#667085] text-Inter">Height</th>
              <th className="border p-2 text-[15.4px] text-[#667085] text-Inter">Weight</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border p-2 text-[15.4px] text-[#667085] text-Inter">{dimensions?.length} in</td>
              <td className="border p-2 text-[15.4px] text-[#667085] text-Inter">{dimensions?.width} in</td>
              <td className="border p-2 text-[15.4px] text-[#667085] text-Inter">{dimensions?.height} in</td>
              <td className="border p-2 text-[15.4px] text-[#667085] text-Inter">
                <p>{(dimensions?.weight * 0.00220462).toFixed(3)} lbs</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
