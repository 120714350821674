import { useEffect, useState } from "react";
import { RangeSelector } from "./RangeSelector";

export const RankAndPrices = ({ rankPrice }) => {
  const [RanksPrices, setRanksPrices] = useState([]);
  const [range, setRange] = useState("30");

  useEffect(() => {
    try {
      console.log("rankPrice", range);
      generateForEach(range);
    } catch (error) {
      console.error(error);
    }
  }, [range]);

  const generateForEach = (range) => {
    if (range === "current") {
      const currentData = [
        // {
        //   title: "BSR (Top %)",
        //   value: rankPrice.bsr_ranks[`value_${range}_days`].toLocaleString(),
        // },
        {
          title: "Buy Box",
          value: `$${(rankPrice.current.buyBoxPrice / 100).toFixed(2)}`,
        },
        // {
        //   title: "Lowest FBA (Sellers)",
        //   value: `$${(rankPrice.FBA_prices[`min_${range}`] / 100).toFixed(2)}`,
        // },
        // {
        //   title: "Lowest FBM (Sellers)",
        //   value: `$${(rankPrice.FBM_prices[`min_${range}`] / 100).toFixed(2)}`,
        // },
        // {
        //   title: "Keepa BSR Drops",
        //   value: `${rankPrice[`keepa_bsr_drops_${range}`]} in last ${range} days`,
        // },
        // {
        //   title: "Net BB Price Changes",
        //   value: `${rankPrice.bb_prices[`bb_drops_${range}`]} in last ${range} days`,
        // },
      ];
      setRanksPrices(currentData);
      return;
    }

 const validateValue = (value, sym) => {
   const parsedValue = parseFloat(value);
   if (!isNaN(parsedValue) && parsedValue > 0) {
     const hasDecimal = parsedValue % 1 !== 0;
     const options = hasDecimal ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } : { minimumFractionDigits: 0 };
     let formattedValue = parsedValue.toLocaleString(undefined, options);
     if (sym) return sym + formattedValue;
     return formattedValue;
   } else {
     return "-";
   }
 };

    const currentData = [
      {
        title: "BSR (Top %)",
        value: validateValue(rankPrice.bsr_ranks[`value_${range}_days`]),
      },
      {
        title: "Buy Box",
        value: validateValue(rankPrice.bb_prices[`data_${range}`]["avg"] / 100, "$"),
      },
      {
        title: "Lowest FBA (Sellers)",
        value: validateValue(rankPrice.FBA_prices[`min_${range}`] / 100, "$"),
      },
      {
        title: "Lowest FBM (Sellers)",
        value: validateValue(rankPrice.FBM_prices[`min_${range}`] / 100, "$"),
      },
      {
        title: "Keepa BSR Drops",
        value: `${rankPrice[`keepa_bsr_drops_${range}`]} in last ${range} days`,
      },
      {
        title: "Net BB Price Changes",
        value: `${rankPrice.bb_prices[`bb_drops_${range}`]} in last ${range} days`,
      },
    ];
    setRanksPrices(currentData);
  };

  return (
    <div className="mt-5 pt-2 pb-4 px-5 border-[1px] rounded-lg space-y-3">
      <p className="text-Inter text-gray-800 font-semibold text-xl">Ranks & Prices</p>
      <RangeSelector range={range} setRange={setRange} />
      <div className="mt-3">
        {RanksPrices.map((item, index) => (
          <div
            key={index}
            className={`flex item-center px-2 justify-between py-1.5 ${index % 2 === 0 && `bg-[#F8F9FC]`}`}
          >
            <p>{item.title}</p>
            <p>{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
