import { useState, useEffect } from "react";

export const ImageSection = ({ image, demoImages }) => {
  const [currentImage, setCurrentImage] = useState(image);

  useEffect(() => {
    //change the image every 5 seconds
    const interval = setInterval(() => {
      setCurrentImage(demoImages[Math.floor(Math.random() * demoImages.length)]);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container mx-auto px-4 w-full">
      <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 ">
        <div className="lg:col-span-3 lg:row-end-1">
          <div className="lg:flex lg:items-start">
            <div className="lg:order-2 lg:ml-5">
              <div className="max-w-xl overflow-hidden h-[400px] bg-red-400 rounded-lg">
                <img className="h-full max-h-[400px] w-full max-w-full object-cover" src={currentImage} alt="" />
              </div>
            </div>

            <div className="mt-2 w-full lg:order-1 lg:w-28 lg:flex-shrink-0">
              <div className="flex flex-row items-start lg:flex-col">
                {demoImages.map((image, index) => (
                  <button
                    key={index}
                    type="button"
                    className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-gray-400 text-center"
                  >
                    <img
                      onClick={() => {
                        setCurrentImage(image);
                      }}
                      className="h-full w-full object-cover"
                      src={image}
                      alt=""
                    />
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
