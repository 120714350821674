import React, { useState, useEffect } from "react";
import { Input, Dropdown, Space, Button, Menu } from "antd";
import { DownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Col, InputNumber, Row, Slider, Typography, Collapse } from "antd";

export const ProfitCalculator = ({ profitCalculator,actualPrice }) => {
  // console.log(profitCalculator);
  const [price, setPrice] = useState({
    costPrice: parseFloat(actualPrice.costPrice),
    salePrice: parseFloat(actualPrice.salePrice),
  });
  const [fbmCost, setFbmCost] = useState(undefined);
  const [roi, setRoi] = useState(parseFloat(profitCalculator.roi.replace("%", "")));
  const [storageFee, setStorageFee] = useState(parseFloat(profitCalculator.total_fees.breakdown.storageFees));
  const [storage, setStorage] = useState(1);
  const [totalFees, setTotalFees] = useState(profitCalculator.total_fees);
  const [fullType, setFType] = useState("FBA");
  const [profit, setProfit] = useState(parseInt(profitCalculator.profit));
  const [maxCost, setMaxCost] = useState(parseInt(profitCalculator.max_cost));
  const [profitMargin, setProfitMargin] = useState(profitCalculator.profitMargin.replace("%", ""));
  const [breakEvenSale, setBreakEvenSale] = useState(profitCalculator.breakeven_sale_price);
  const [estimatedAmzPayout, setEstimatedAmzPayout] = useState(profitCalculator.est_amz_payout);

  // console.log("price", price);

  const calculateMaxCost = (sale_price, amazonFees, roi) => {
    let max_cost = (sale_price - amazonFees) / (1 + (roi / 100))
    return max_cost.toFixed(2);
  }

  const calculateProfit = (sale_price, cost_price, amazonFees) => {
    let profit = sale_price - (cost_price + amazonFees)
    return profit.toFixed(2)
  }

  const getProfitMargin = (sale_price, cost_price, amazonFees) => {
    let profit = sale_price - (+cost_price + +amazonFees);
    return ((profit / parseFloat(sale_price)) * 100).toFixed(2) + "%";
  };

  const calculateROI = (cost_price, sale_price, amazonFees) => {
    const final_sale_price = sale_price - amazonFees;
    const roi = ((final_sale_price - cost_price) / cost_price) * 100;
    return roi.toFixed(2);
  };

  useEffect(() => {
    const roi = calculateROI(price.costPrice, price.salePrice, profitCalculator.total_fees.total)
    setRoi(() => roi)

    const maxCost = calculateMaxCost( price.salePrice,profitCalculator.total_fees.total, roi)
    setMaxCost(() => maxCost)

    console.table(price.salePrice, price.costPrice, profitCalculator.total_fees.total);
    const profit = calculateProfit(price.salePrice, price.costPrice,  profitCalculator.total_fees.total)
    setProfit(() => profit)

    const profitMargin = getProfitMargin(price.salePrice, price.costPrice,  profitCalculator.total_fees.total)
    setProfitMargin(() => profitMargin)


     const breakeven_sale_price = price.costPrice + profitCalculator.total_fees.total
     setBreakEvenSale(() => breakEvenSale)

    const est_amz_payout = price.salePrice - profitCalculator.total_fees.total
    setEstimatedAmzPayout(() => est_amz_payout)

    console.log("setEverything")

  },[])

  useEffect(() => {
    calculateROIProfit();
  }, [price.costPrice, price.salePrice, totalFees.total]);

  const changeStorage = (newValue) => {
    setStorage(newValue);
    let fee = parseFloat((parseFloat(profitCalculator.total_fees.breakdown.storageFees) * newValue).toFixed(2));
    setStorageFee(fee);
    // console.log(fee);
    // console.log("total_fees.total", profitCalculator.total_fees.total);
    let netTotal = parseFloat(
      parseFloat(profitCalculator.total_fees.total) -
      parseFloat(profitCalculator.total_fees.breakdown.storageFees) +
      fee
    ).toFixed(2);

    if (newValue === 1) setTotalFees({ ...totalFees, total: profitCalculator.total_fees.total });
    else setTotalFees({ ...totalFees, total: netTotal });
  };

  const calculateROIProfit = () => {
    // roi and profit
    let final_sale_price = price.salePrice - parseFloat(totalFees.total);
    let total_cost_price = price.costPrice + parseFloat(totalFees.total);
    let roi = parseFloat((((final_sale_price - price.costPrice) / price.costPrice) * 100).toFixed(2));
    // console.log("roi", roi);
    setRoi(roi);

    let prof = (price.salePrice - total_cost_price).toFixed(2);
    // console.log("prof", prof);
    setProfit(prof);

    // console.log(profit, price.salePrice);
    // [profit margin]
    let prof_margin = ((prof / price.salePrice) * 100).toFixed(2);
    setProfitMargin(prof_margin);
    // [max cost]
    // console.log("totalFees.total", totalFees.total);
    // console.log("roi", roi);
    // console.log(roi / 100);
    let max_cost = ((price.salePrice - parseFloat(totalFees.total)) / (1 + roi / 100)).toFixed(2);
    // console.log(max_cost);
    setMaxCost(max_cost);

    // [break even sale]
    let bes = parseFloat(max_cost) + parseFloat(totalFees.total);
    setBreakEvenSale(bes.toFixed(2));

    // [EstimatedAmzPayout]
    let est = price.salePrice - parseFloat(totalFees.total);
    setEstimatedAmzPayout(est.toFixed(2));
  };

  const fbaStorageDropdown = [
    {
      key: "1",
      label: (
        <Button className={`text-grey`} onClick={() => setFType("FBM")}>
          FBM
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button className={`text-grey`} onClick={() => setFType("FBA")}>
          FBA
        </Button>
      ),
    },
  ];
  useEffect(() => {
    if (fbmCost) {
      let totalfee = parseFloat(profitCalculator.total_fees.total) + parseFloat(fbmCost);
      setTotalFees({ ...totalFees, total: totalfee.toFixed(2) });
    } else {
      setTotalFees({ ...totalFees, total: profitCalculator.total_fees.total });
    }
  }, [fbmCost]);
  const dropdownMenu = <Menu items={fbaStorageDropdown} />;
  const { Title } = Typography;

  const fullFillmentTypeSelection = () => {
    if (fullType === "FBA") {
      return (
        <div className={`flex items-center justify-between px-3  py-1.5`}>
          <p className="text-Inter leading-4">Storage (Months)</p>
          <div
            className="flex items-center w-40 justify-between py-1 px-3"
            style={{ position: "relative", left: "-2rem" }}
          >
            <Row>
              <Col span={12}>
                <Slider min={1} max={20} onChange={changeStorage} value={typeof storage === "number" ? storage : 0} />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={20}
                  style={{
                    margin: "0 16px",
                  }}
                  value={storage}
                  onChange={changeStorage}
                />
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return (
        <div className={`flex items-center justify-between px-3  py-1.5 bg-[#F8F9FC] `}>
          <p className="text-Inter leading-4">FBM Cost</p>
          <div className="flex items-center w-40 justify-between py-1 px-3">
            <InputNumber
              className="w-40"
              placeholder="FBM Cost"
              prefix="$"
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              value={fbmCost}
              onChange={(value) => {
                setFbmCost(value);
              }}
            />
          </div>
        </div>
      );
    }
  };

  const totalFee = [
    {
      key: "1",
      label: (
        <div className={`flex items-center px-3 rounded-md justify-between   bg-[#F8F9FC] `}>
          <p className="text-Inter leading-4" style={{ position: "relative", left: "-.3rem", fontSize: "medium" }}>
            Total fees{" "}
            <CaretRightOutlined
            // rotate={isActive ? 90 : 0}
            />
          </p>
          <div className="flex items-center justify-between     px-2">
            <p style={{ position: "relative", fontSize: "large", fontWeight: "600", left: "-.3rem" }}>
              ${parseFloat(totalFees.total)}{" "}
            </p>
          </div>
        </div>
      ),
      showArrow: false,
      children: (
        <>
          <div className={`flex items-center justify-between px-3 py-1.5  `}>
            <p className="text-Inter leading-4">Referral Fee</p>
            <div className="flex items-center  justify-between py-1 px-3">
              <p className="text-Inter font-semibold text-gray-600 text-md leading-4">
                ${totalFees.breakdown.referralFees}
              </p>
            </div>
          </div>
          <div className={`flex item-center px-2 justify-between  px-3 py-1.5 `}>
            <p className="text-Inter leading-4">Fullfiment (FBA)</p>
            <div className="flex items-center  justify-between py-1 px-3">
              <p className="text-Inter font-semibold text-gray-600 text-md leading-4">${totalFees.breakdown.FBAFees}</p>
            </div>
          </div>
          <div className={`flex item-center px-2 justify-between  px-3 py-1.5 `}>
            <p className="text-Inter leading-4">Storage Fee</p>
            <div className="flex items-center  justify-between py-1 px-3">
              <p className="text-Inter font-semibold text-gray-600 text-md leading-4">$ {storageFee} </p>
            </div>
          </div>
          <div className={`flex item-center px-2 justify-between  px-3 py-1.5 `}>
            <p className="text-Inter leading-4">Prep Fee</p>
            <div className="flex items-center  justify-between py-1 px-3">
              <p className="text-Inter font-semibold text-gray-600 text-md leading-4">
                ${totalFees.breakdown.prep_fee}
              </p>
            </div>
          </div>
          <div className={`flex item-center px-2 justify-between  px-3 py-1.5 `}>
            <p className="text-Inter leading-4">Inbound Shipping</p>
            <div className="flex items-center  justify-between py-1 px-3">
              <p className="text-Inter font-semibold text-gray-600 text-md leading-4">
                $ {totalFees.breakdown.inbound_shipping}
              </p>
            </div>
          </div>
          <div className={`flex item-center px-2 justify-between  px-3 py-1.5 `}>
            <p className="text-Inter leading-4">Misc Fee</p>
            <div className="flex items-center  justify-between py-1 px-3">
              <p className="text-Inter font-semibold text-gray-600 text-md leading-4">
                ${totalFees.breakdown.misc_fee}
              </p>
            </div>
          </div>
          <div className={`flex item-center px-2 justify-between  px-3 py-1.5 `}>
            <p className="text-Inter leading-4">Misc Fee (% of cost)</p>
            <div className="flex items-center  justify-between py-1 px-3">
              <p className="text-Inter font-semibold text-gray-600 text-md leading-4">
                {totalFees.breakdown.misc_fee_percentage}%
              </p>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="mt-4 pb-4  space-y-3">
      <p className="text-Inter text-gray-800 font-semibold text-xl">Profit Calculator</p>
      <div className="mt-3">
        <div className={`flex items-center justify-between px-3  py-1.5`}>
          <p className="text-Inter leading-4">Cost Price</p>
          <div className="flex items-center w-40 justify-between py-1 px-3">
            <InputNumber
              className="w-40"
              //   type="number"
              placeholder="Cost Price"
              prefix="$"
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              value={price.costPrice}
              onChange={(value) => {
                setPrice({ ...price, costPrice: value });
              }}
            />
          </div>
        </div>
        <div className={`flex items-center px-3 rounded-md justify-between py-1.5 bg-[#F8F9FC]`}>
          <p className="text-Inter">Sale Price</p>
          <div className="flex items-center w-40 justify-between    py-1 px-3">
            <InputNumber
              className="w-40"
              placeholder="Sales Price"
              prefix="$"
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              value={price.salePrice}
              onChange={(value) => {
                setPrice({ ...price, salePrice: value });
              }}
            />
          </div>
        </div>
        <div className={`flex items-center justify-between px-3  py-1.5`}>
          <p className="text-Inter leading-4">Fullfilment Type</p>
          <div className="flex items-center justify-between py-1 px-3">
            <Dropdown overlay={dropdownMenu}>
              <Button className={`text-grey`}>
                {" "}
                <Space>
                  {fullType}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div>

        {fullFillmentTypeSelection()}
        <div className={`flex items-center justify-between px-3  py-1.5`}>
          <p className="text-Inter leading-4">Profit</p>
          <div className="flex items-center  justify-between py-1 px-7">
            <p style={{ fontSize: "medium", fontWeight: "600", color: profit <= 0 ? "#bf1815" : "#39960e" }}>
              ${profit}
            </p>
          </div>
        </div>
        <div className={`flex items-center justify-between px-3  py-1.5 bg-[#F8F9FC]`}>
          <p className="text-Inter leading-4">ROI</p>
          <div className="flex items-center  justify-between py-1 px-6">
            <p style={{ fontSize: "medium", fontWeight: "600", color: roi <= 0 ? "#bf1815" : "#39960e" }}>{roi}%</p>
          </div>
        </div>
        <div className={`flex items-center justify-between px-3  py-1.5 `}>
          <p className="text-Inter leading-4">Maximum Cost</p>
          <div className="flex items-center  justify-between  py-1 px-6">
            <p
              style={{
                fontSize: "medium",
                fontWeight: "600",
                color: maxCost <= 0 ? "#bf1815" : "#39960e",
              }}
            >
              ${maxCost}
            </p>
          </div>
        </div>
        <Collapse
          items={totalFee}
          bordered={false}
          size="small"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        />
        <div className={`flex items-center justify-between px-3  py-1.5 `}>
          <p className="text-Inter leading-4">Profit Margin</p>
          <div className="flex items-center  justify-between py-1 px-5">
            <p style={{ fontSize: "medium", fontWeight: "600" }}>{profitMargin}%</p>
          </div>
        </div>
        <div className={`flex items-center justify-between px-3  py-1.5 bg-[#F8F9FC]`}>
          <p className="text-Inter leading-4">Breakeven Sale Price</p>
          <div className="flex items-center  justify-between py-1 px-5">
            <p style={{ fontSize: "medium", fontWeight: "600" }}>${breakEvenSale}</p>
          </div>
        </div>
        <div className={`flex items-center justify-between px-3  py-1.5 `}>
          <p className="text-Inter leading-4">Estimated Amz. Payout</p>
          <div className="flex items-center  justify-between py-1 px-5">
            <p style={{ fontSize: "medium", fontWeight: "600" }}>${estimatedAmzPayout}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
