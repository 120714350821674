import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, handleSignOut } from '../redux/actions/auth';
import { createSubscription, upgradeSubscription } from './Subscription/CreateSubscription';
import { toast } from "sonner";
import { motion } from 'framer-motion'



const SignupPricingTable = ({ planSelected, setPlanSelected }) => {


    const [loading, setLoading] = useState(null);
    const [isMonthly, setIsMontly] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const plans = [
        // {
        //     id: 4,
        //     name: "Free",
        //     price: "0.00",
        //     features: ["Access to Free List", "Access Academy", "Add to Favourites"],
        //     plan: "free",
        //     stripePlanId: process.env.REACT_APP_FREE_PRICE_ID_MONTHLY,
        //     isMonthly: true
        // },
        {
            name: "Bronze",
            price: "97",
            features: ["2 winning products a day", "Minimum $2.00 profit per unit"],
            plan: "pro",
            isMonthly: true,
            stripePlanId: process.env.REACT_APP_PRO_PRICE_ID_MONTHLY,
        },
        {
            name: "Silver",
            price: "147",
            features: [
                "3 winning products a day",
                "Minimum $2.00 Profit per unit",
                "Unlimited Support",

            ],
            popular: false,
            plan: "premium",
            isMonthly: true,
            stripePlanId: process.env.REACT_APP_ADVANCED_PRICE_ID_MONTHLY,
        },
        {
            name: "Gold",
            price: "167",
            features: [
                "3 winning products a day",
                "Products over 100 sales a month",
                "Products over $3.50 profit per unit when posted",
                "Unlimited Support",

            ],
            plan: "specialBase",
            isMonthly: true,
            stripePlanId: process.env.REACT_APP_POPULAR_PRICE_ID_MONTHLY,
        },
        {
            name: "Bronze",
            price: "850",
            features: ["2 winning products a day", "Minimum $2.00 profit per unit", "Community Access"],
            plan: "pro",
            isMonthly: false,
            stripePlanId: process.env.REACT_APP_PRO_PRICE_ID_YEARLY,
        },
        {
            name: "Silver",
            price: "1237",
            features: [
                "3 winning products a day",
                "Minimum $2.00 Profit per unit",
                "Unlimited Support",

            ],
            popular: false,
            plan: "premium",
            isMonthly: false,
            stripePlanId: process.env.REACT_APP_ADVANCED_PRICE_ID_YEARLY,
        },
        {
            name: "Gold",
            price: "1450",
            features: [
                "3 winning products a day",
                "Products over 100 sales a month",
                "Products over $3.50 profit per unit when posted",
                "Unlimited Support",

            ],
            plan: "specialBase",
            isMonthly: false,
            stripePlanId: process.env.REACT_APP_POPULAR_PRICE_ID_YEARLY,
        },
    ];

    const newPlans = [
        {
            id: 1,
            name: "Montly",
            price: "75",
            features: ["2 winning products a day", "Minimum $2.00 profit per unit"],
            plan: "pro",
            isMonthly: true,
            stripePlanId: process.env.REACT_APP_PRO_PRICE_ID_MONTHLY,
        },
        {
            id: 2,
            name: "Annually",
            price: "675",
            features: ["2 winning products a day", "Minimum $2.00 profit per unit", "Community Access"],
            plan: "pro",
            isMonthly: false,
            stripePlanId: process.env.REACT_APP_PRO_PRICE_ID_YEARLY,
        },
    ]


    const buyPlan = async (plan) => {
        // console.log(plan, "plan.id", user);
        // try {
        //     const { url } = await createSubscription(
        //         user.userId,
        //         user.userEmail,
        //         plan.stripePlanId,
        //         isMonthly
        //     );

        //     toast.success("Go to checkout!");
        //     window.open(url, "_self");
        //     setLoading(plan);
        //     // dispatch(createCheckoutSession(plan));
        // } catch (err) {
        //     console.log("Something went wrong in buying plan");
        //     console.log(err);
        // } finally {
        //     setLoading(null);
        // }
    };

    useEffect(() => {
        if (planSelected && planSelected?.isMonthly !== true) {
            console.log(planSelected, "planSelected")
            setIsMontly(true)
        }
    }, [])

    return (
        <div className="py-10 relative overflow-hidden">
            {/* 
            <div className='w-full  flex justify-start'>
                <div className="flex items-center justify-center gap-x-2">
                    <svg
                        width="21"
                        height="20"
                        className=""
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20.0153 1.15092C20.0151 1.14964 20.0151 1.14833 20.0153 1.14705C19.9789 0.987773 19.8988 0.841818 19.7839 0.725655C19.6691 0.609491 19.524 0.527741 19.3651 0.489629C18.0847 0.177246 16.0703 0.510254 13.8381 1.404C11.5882 2.30635 9.48018 3.63838 8.05534 5.0658C7.59864 5.52007 7.17436 6.00581 6.78561 6.51943C5.82612 6.47647 4.98092 6.61311 4.26893 6.92377C1.78791 8.01561 1.07936 10.8099 0.890727 11.9571C0.863431 12.1201 0.874119 12.2873 0.921958 12.4455C0.969798 12.6037 1.0535 12.7487 1.16655 12.8693C1.27959 12.9898 1.41894 13.0827 1.57374 13.1406C1.72854 13.1986 1.89462 13.22 2.05905 13.2032H2.06463L4.82838 12.9016C4.83182 12.9368 4.83569 12.969 4.8387 12.9987C4.87419 13.3355 5.02438 13.6498 5.26409 13.889L6.61287 15.2387C6.85172 15.4787 7.16599 15.6291 7.50276 15.6645L7.59514 15.6744L7.29436 18.4347V18.4403C7.27899 18.5889 7.29481 18.7392 7.3408 18.8814C7.3868 19.0236 7.46197 19.1546 7.56149 19.2661C7.66102 19.3776 7.78272 19.4671 7.9188 19.5289C8.05489 19.5907 8.20237 19.6234 8.35182 19.6249C8.41156 19.625 8.4712 19.6201 8.53014 19.6103C9.68385 19.4247 12.4768 18.7247 13.5656 16.2304C13.8737 15.5231 14.0099 14.6813 13.9717 13.7236C14.4876 13.3357 14.9751 12.9114 15.4305 12.4538C16.8669 11.0247 18.2033 8.93385 19.0962 6.71666C19.9852 4.50979 20.3199 2.48037 20.0153 1.15092ZM15.415 8.66529C15.062 9.01872 14.612 9.25949 14.1221 9.35714C13.6322 9.4548 13.1243 9.40494 12.6627 9.21389C12.2011 9.02283 11.8065 8.69916 11.5289 8.28383C11.2514 7.86849 11.1032 7.38015 11.1032 6.88059C11.1032 6.38102 11.2514 5.89268 11.5289 5.47735C11.8065 5.06201 12.2011 4.73834 12.6627 4.54729C13.1243 4.35623 13.6322 4.30638 14.1221 4.40403C14.612 4.50168 15.062 4.74245 15.415 5.09588C15.6517 5.32897 15.8396 5.60679 15.9679 5.91319C16.0961 6.21958 16.1622 6.54843 16.1622 6.88059C16.1622 7.21275 16.0961 7.54159 15.9679 7.84799C15.8396 8.15438 15.6517 8.4322 15.415 8.66529Z"
                            fill="#7A5AF8"
                        />
                    </svg>
                    <p className="text-2xl text-center font-Inter font-bold text-[#101828] ease-in duration-300">
                        Profitboost
                    </p>
                </div>

                <div
                    className="flex gap-x-2 justify-center items-center  border px-5 py-1 rounded-xl bg-gray-300 cursor-pointer text-gray-900"
                    onClick={() => {
                        dispatch(handleSignOut());
                        navigate(`/signin`);
                    }}
                >
                    <span>Sign out</span>
                    <div className="flex items-start -mt-1">
                        <svg
                            width="16"
                            height="16"
                            className="mt-2 cursor-pointer"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.3333 13.1667L16.5 9M16.5 9L12.3333 4.83333M16.5 9H6.5M6.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H6.5"
                                stroke="#6B7080"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>

            </div> */}



            <div className='flex  flex-col gap-y-5  items-center py-3 w-full '>
                {/* <span className='font-bold text-4xl tracking-wide text-[#282876] text-Inter'>Get results first. Pick a plan later.</span> */}
                {/* <span className='text-base tracking-widest font-bold text-[#282876]'>Choose from multiple plans that fit your current stage of growth</span> */}

                <div className='relative '>


                    {/* <div className="overflow-hidden p-0.5 mt-2 border-[0.5px] border-slate-100 shadow-sm rounded-full flex">
                   
                    <button
                        onClick={() => {
                            setIsMontly((item) => !item)
                        }}
                        class={` px-5 w-1/2 sm:w-auto py-2.5 font-semibold rounded-full transition-all ease-in-out duration-200 ${!isMonthly ? `text-white bg-primary` : `text-gray-800 bg-transparent `} `}>Monthly</button>
                    <button
                        onClick={() => {
                            setIsMontly((item) => !item)
                        }}
                        class={` px-4 w-1/2 sm:w-auto py-2.5 font-semibold rounded-full transition-all ease-in-out duration-200 ${isMonthly ? `text-white bg-primary` : `text-gray-800 bg-transparent `}`}>Yearly</button>
                </div> */}




                </div>
                <section className="text-gray-600 body-font overflow-hidden mt-10">
                    <div className="space-y-5">
                        <h2 className='text-2xl font-bold text-gray-800 text-center'>Pricing Plans</h2>
                        <div className="flex flex-wrap justify-center gap-x-2">
                            {newPlans.map((item, key) => {
                                return (
                                    (
                                        <motion.div
                                            key={item.stripePlanId}
                                            initial={{ opacity: 0.2, x: 50 }}
                                            animate={{ opacity: 1, x: 0 }}
                                            transition={{ type: "spring", damping: 25, duration: 0.8, ease: "easeInOut" }}
                                            className={`p-2 xl:w-1/3 md:w-1/2 w-full transition-all relative flex-1 duration-100 ease-in-out`}>
                                            <div className={`h-full w-full p-5  rounded-lg border-[1px] flex flex-col transition-all duration-0 ease-in-out overflow-hidden ${key == 1  && 'bg-gradient-to-b from-[#947cee] via-[#9078eb] to-[#826ae3]  text-white'}`}>
                                                <h2 className={`text-md tracking-widest mb-2 ${key == 1 ? 'text-white' : 'text-[#8181ac]'} font-medium text-Inter`}>
                                                    {item.name}
                                                </h2>
                                                <h1 className={`text-3xl font-semibold ${key == 1 ? 'text-white' : 'text-[#282876]'} leading-none flex items-end text-Inter mb-2 transition-all ease-linear duration-200`}>
                                                    <span className='transition-all ease-linear duration-200'>{item.price === "0.00" ? 'Free' : `$${item.price}`}</span>
                                                    <span className={`text-lg ml-1 font-normal flex items-center ${key == 1 ? 'text-white' : 'text-[#8181ac]'} text-Inter text-sm text-Inter`}>{item.isMonthly ? '/mo' : '/yr'}</span>
                                                </h1>

                                                <div className="mt-2.5 space-y-3 mb-4">
                                                    {item.features.map((feature) => (
                                                        <p key={key} className={`flex items-center ${key == 1 ? 'text-white' : 'text-[#8181ac]'} text-Inter text-sm`}>
                                                            <span className={`w-4 h-4 mr-2 inline-flex items-center justify-center ${planSelected?.price === item.price ? 'bg-gray-200' : 'bg-orange-200'} text-white rounded-full flex-shrink-0`}>
                                                                {/* Your SVG icon here */}
                                                            </span>
                                                            {feature}
                                                        </p>
                                                    ))}
                                                </div>
                                                <div className='mt-12'>

                                                </div>

                                                {planSelected.stripePlanId === item.stripePlanId ? (
                                                    <button
                                                        disabled
                                                        type="button"
                                                        className="mt-auto mx-auto shadow-sm bg-primary text-white text-Inter py-3 px-4 w-full font-medium focus:outline-none rounded-full transition-all ease-linear duration-100"
                                                    >
                                                        {/* Your loading animation here */}
                                                        SELECTED
                                                    </button>
                                                ) : false ? (
                                                    <button
                                                        className="mt-auto font-semibold font-Inter text-sm block w-full rounded-full transition mx-auto duration-200 ease-in bg-[#f4f5fd] hover:bg-primary py-2.5 text-white"
                                                    >
                                                        Current Plan
                                                    </button>
                                                ) : (
                                                    < button
                                                        onClick={() => setPlanSelected(item)}
                                                        disabled={loading}
                                                        className={`mt-auto mx-auto text-[#282876] shadow-sm border-[0.5px] border-slate-100 bg-[#f4f5fd] hover:bg-primary hover:text-white text-Inter py-3 px-4 w-full font-medium focus:outline-none rounded-full transition-all ease-linear duration-100`}
                                                    >
                                                        Choose
                                                    </button>
                                                )}
                                            </div>
                                        </motion.div>
                                    )
                                )
                            })}
                        </div>
                    </div>
                </section >
            </div>
        </div>
    )
}

export default SignupPricingTable
