import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ImageSection, ProductDescription, Overview, RankAndPrices, Chart } from "../components/ProductDetails";
import {
  Variations,
  ViewProduct,
  TimeAndShipping,
  Tags,
  ProfitCalculator,
  Rating,
  KeepaChart,
} from "../components/ProductDetails";
import authenticatedInstance from "../redux/actions/AxiosInstance/authenticated";
import { Spin } from "antd";

export const ProductDetails = (props) => {
  const [product, setProduct] = useState();
  const [loader, setLoader] = useState(false);
  const [currentImage, setCurrentImage] = React.useState();
  const [productId, setProductId] = useState();
  const [productStatus, setProductStatus] = useState("open");
  const [favoriteStatus, setFavoriteStatus] = useState(false);
  const [notes, setNotes] = useState("");
  const [actualPrice, setActualPrice] = useState({
    salePrice: 0,
    costPrice: 0
  })
  const products = useSelector((state) => state.product.products);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const pathname = window.location.pathname;
    const parts = pathname.split("/");
    const productId = parts[parts.length - 1];

    async function getProductDetails() {
      setLoader(true);
      try {
        if (user) {
          const { userId } = user;
            const { data } = await authenticatedInstance.get(`/product/productDetails/${productId}?user_id=${userId}`);
            console.log(data,'product details')
            setProduct(data?.productJson);
            setActualPrice({
              salePrice: data?.salePrice,
              costPrice: data?.costPrice
            })
            setFavoriteStatus(data?.favorite);
            setProductId(data?.id);
            setProductStatus(data?.status);
            setCurrentImage(data?.productJson.image);
            setNotes(data?.notes);
        }

        // setFavouriteProducts(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false);
      }
    }
    getProductDetails();
  }, [user]);

  // useEffect(() => {
  //   if (products && products.length > 0) {
  //     const pathname = window.location.pathname;
  //     const parts = pathname.split("/");
  //     const productId = parts[parts.length - 1];

  //     var filteredProduct = products.find((product) => product.productJson.asin === productId);

  //     setActualPrice({
  //       salePrice: filteredProduct?.salePrice,
  //       costPrice: filteredProduct?.costPrice
  //     })
  //   }
  // }, [products])


  const navigate = useNavigate();
  // const demoImages = [
  //   "https://imageio.forbes.com/specials-images/imageserve/641397e79f04500b85460b8f/Apple--iPhone-15--iPhone-15-Pro-Max--iPhone-15-Pro--iPhone-15-Pro-design--iPhone-15/0x0.jpg?format=jpg&crop=924,693,x359,y0,safe&width=960",
  //   "https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/iphone-card-40-iphone15prohero-202309_FMT_WHH?wid=508&hei=472&fmt=p-jpg&qlt=95&.v=1693086369818",
  //   "https://images.macrumors.com/t/TkNh1oQ0-9TnnBjDnLyuz6yLkjE=/1600x0/article-new/2023/09/iPhone-15-General-Feature-Black.jpg",
  //   "https://images.macrumors.com/t/3q1wCX8q2ny5ej3QuD9ERZJsWDA=/800x0/smart/article-new/2019/10/iphone12-lineup-wide.jpg?lossy",
  // ];
  const demoImages = [product?.image];

  return (
    <div className="py-4 w-full overflow-y-auto min-h-screen relative">
      <Spin spinning={loader} size="large">
        {product && (
          <>
            <div className="flex-1 px-5 sm:px-5 w-full flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex items-baseline gap-x-4">
                <svg
                  onClick={() => {
                    navigate("lists");
                  }}
                  width="8"
                  height="14"
                  className="cursor-pointer"
                  viewBox="0 0 7 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.57294 0.482788C6.9503 0.784671 7.01148 1.3353 6.7096 1.71266L2.48021 6.99938L6.70959 12.2861C7.01148 12.6635 6.9503 13.2141 6.57294 13.516C6.19559 13.8179 5.64496 13.7567 5.34307 13.3793L0.676407 7.54599C0.420754 7.22642 0.420754 6.77234 0.676407 6.45277L5.34307 0.61944C5.64496 0.242085 6.19559 0.180904 6.57294 0.482788Z"
                    fill="#2B2B2B"
                  />
                </svg>

                <p className="text-2xl font-Inter font-semibold text-[#101828] ease-in duration-300 fade-in">
                  {product.basicInfo.productName}
                </p>
              </div>
            </div>

            <hr className="bg-gray-200 h-[1.15px] mt-3" />

            <div className="px-8 flex flex-start mt-3">
              <div className="w-1/2">
                {/* Images section */}
                <ImageSection image={currentImage} demoImages={demoImages} />

                {/* Product description */}
                {
                  product?.description && <ProductDescription description={product?.description} dimensions={product.dimensions} />
                }
                {/* <ProductDescription description={product?.description} dimensions={product.dimensions} /> */}

                {/* Overview */}
                <Overview
                  overview={product.overview}
                  productId={productId}
                  productStatus={productStatus}
                  product={product}
                  actualPrice={actualPrice}
                  profitCalculator={product.profitCalculator}
                />

                {/* Ranks & Prices */}
                <RankAndPrices rankPrice={product.ranksandprices} />

                {/* Chart-1 */}
                {/* <Chart chartdata={chartdata} /> */}
                {/* Chart-2 */}
                {/* <Chart chartdata={product.ranksandprices} title={"FBA FBM"} categories={["FBM", "FBA"]} /> */}

                {/* Variations */}
                {product.variations.length > 0 && <Variations variations={product.variations} />}

                {/* KeepaChart */}
                <KeepaChart asin={product.asin} />
              </div>
              <div className="w-1/2 py-4 px-8 ">
                <div className="border-[1px] border-slate-200 px-5 py-5 rounded-lg">
                  <p className="text-Inter text-slate-700 font-bold text-2xl">{product.basicInfo.productName}</p>
                  <p className="text-Inter text-gray-500 text-sm py-2">ASIN: {product.asin}</p>
                  {/* <p className="text-Inter text-gray-500 text-sm py-2">Brand: {product.basicInfo.brand}</p> */}
                  <Rating rating={product.rating} favouriteStatus={favoriteStatus} productId={productId} />


                  <div className="mt-3 flex items-start gap-x-3 text-center py-2">
                    <p className="text-Inter py-1  text-gray-500 text-sm ">Status:</p>
                    <p className="text-Inter text-sm text-green-600 bg-green-100 py-1 px-2.5 w-fit rounded-md flex items-start gap-x-3md">
                      {productStatus}
                    </p>
                  </div>

                  {/* viewproduct */}
                  {/* <ViewProduct amazonLink={product.sellerCentral.amazonLink} /> */}
                  <div className="flex items-center justify-between mt-5 px-2">
                    <div>
                      <p className="text-Inter text-sm text-gray-500 leading-5">Cost Price</p>
                      <p className="text-Inter text-slate-700 font-bold text-2xl">
                        ${actualPrice.costPrice}
                      </p>
                    </div>
                    <div>
                      <p className="text-Inter text-sm text-gray-500 leading-5">Selling Price</p>
                      <p className="text-Inter text-slate-700 font-bold text-2xl">
                        ${actualPrice.salePrice}
                      </p>
                    </div>
                  </div>

                  <div className="mt-4 px-2 space-y-3">
                    <button
                      // disabled
                      // disabled={product.sellerCentral.sourceLink !== "" ? true : false}
                      className="font-semibold font-Inter text block w-full rounded-lg transition duration-200 ease-in bg-primary hover:bg-primary py-3 text-white"
                      // onClick={() => (window.location.href = product.sellerCentral.sourceLink)}
                      onClick={() => window.open(product.sellerCentral.sourceLink, "_blank")}
                    >
                      View Source
                    </button>
                    <button
                      className="font-semibold font-Inter block w-full rounded-lg transition duration-200 ease-in border-[1px] border-slate-200 hover:bg-primary hover:text-white py-3 text-gray-600"
                      // onClick={() => (window.location.href = product.sellerCentral.amazonLink)}
                      onClick={() => window.open(product.sellerCentral.amazonLink, "_blank")}
                    >
                      View on Amazon
                    </button>
                  </div>

                  {
                    notes && (
                      <p className="text-Inter text-sm text-gray-500 mt-4 px-2"> <span className="font-semibold text-gray-900">Note: </span>
                        {notes}
                      </p> 
                    )
                  }


                  {/* timeandshipping */}
                  <TimeAndShipping processingTime={product.processing_time} details={product.delivery_details} />

                  <hr className="bg-gray-200 h-[1.15px] mt-2 px-2" />

                  <Tags product={product} />
                  <hr className="bg-gray-200 h-[1.15px] mt-4 px-2" />
                  {/* Profit Calculator */}
                  <ProfitCalculator profitCalculator={product.profitCalculator} actualPrice={actualPrice} />
                </div>
              </div>
            </div>
          </>
        )}
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
