import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { handleSignIn, handleSignUp } from "../../redux/actions/auth";
import SignupPricingTable from "../../components/SignupPricingTable";
import { useDispatch } from "react-redux";
import {
  CardElement,
  PaymentElement,
  useStripe, useElements
} from '@stripe/react-stripe-js'
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { toast } from "sonner";

function Authentication() {


  // stripe
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);

  // get current route
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname.slice(1);



  const defaultCardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
    hidePostalCode: true,
  };


  // state variables
  const [rememberState, setRememberState] = useState(false);
  const [planSelected, setPlanSelected] = useState({
    id: 4,
    name: "Bronze",
    price: "97",
    features: ["2 winning products a day", "Minimum $2.00 profit per unit", "Community Access"],
    plan: "pro",
    isMonthly: true,
    stripePlanId: process.env.REACT_APP_PRO_PRICE_ID_MONTHLY,
  });

  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    name: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("setupModalStatus");
  }, []);

  // handle Input
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setLoginData({ ...loginData, [name]: value });
  };

  // handle submit
  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      if (currentRoute === "signup") {

        console.log('checking if stripe and planSelected exists', stripe, elements, planSelected)

        if (!stripe || !elements || !planSelected) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          console.log("stripe not loaded")

          toast.error("Something went wrong!");
          return;
        }

        console.log('creating payment method')

        const cardElement = elements.getElement(CardElement);

        console.log(cardElement, "cardElement")

        if (!cardElement) {
          console.error('Card element not found');
          return;
        }



        // Create a payment method using the card Element
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        console.log(result, "result")

        if (result.error) {
          // Handle errors
          console.error(result.error.message);
          toast.error(result.error.message);
          setMessage(result.error.message);
          setLoading(false);
          return;
        }

        let res = await handleSignUp({ ...loginData, paymentMethodId: result.paymentMethod.id, planSelected: planSelected });
        if (res) {
          await signIn(loginData);
          // navigate("/signin");
        }
      } else {
        let res = await handleSignIn(loginData);
        console.log(res, "res");
        if (res?.success) {
          dispatch({ type: "SET_USER", payload: res.data });
          navigate("/lists");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const signIn = async (loginData) => {
    let res = await handleSignIn(loginData);
    console.log(res, "res");
    if (res) {
      dispatch({
        type: "SET_USER",
        payload: res.data,
      });

      navigate("/lists");
    } else {
      dispatch({
        type: "CHANGE_ALERT_STATE",
        alerttype: "failed",
        heading: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  return (
    <div className={`${currentRoute === 'signup' ? 'flex flex-col lg:flex-row  w-full items-center lg:items-start  lg:justify-between  min-h-screen fade-in ' : 'grid min-h-screen place-items-center fade-in'}`}>
      <div className={` ${currentRoute === "signup" ? 'lg:w-[40%] pt-10 lg:pt-24 flex flex-col lg:pl-72 lg:pr-12' : 'w-full sm:max-w-sm xs:max-w-xm '}`}>
        <div className="flex items-center justify-start gap-x-2">
          <svg width="21" height="20" className="" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.0153 1.15092C20.0151 1.14964 20.0151 1.14833 20.0153 1.14705C19.9789 0.987773 19.8988 0.841818 19.7839 0.725655C19.6691 0.609491 19.524 0.527741 19.3651 0.489629C18.0847 0.177246 16.0703 0.510254 13.8381 1.404C11.5882 2.30635 9.48018 3.63838 8.05534 5.0658C7.59864 5.52007 7.17436 6.00581 6.78561 6.51943C5.82612 6.47647 4.98092 6.61311 4.26893 6.92377C1.78791 8.01561 1.07936 10.8099 0.890727 11.9571C0.863431 12.1201 0.874119 12.2873 0.921958 12.4455C0.969798 12.6037 1.0535 12.7487 1.16655 12.8693C1.27959 12.9898 1.41894 13.0827 1.57374 13.1406C1.72854 13.1986 1.89462 13.22 2.05905 13.2032H2.06463L4.82838 12.9016C4.83182 12.9368 4.83569 12.969 4.8387 12.9987C4.87419 13.3355 5.02438 13.6498 5.26409 13.889L6.61287 15.2387C6.85172 15.4787 7.16599 15.6291 7.50276 15.6645L7.59514 15.6744L7.29436 18.4347V18.4403C7.27899 18.5889 7.29481 18.7392 7.3408 18.8814C7.3868 19.0236 7.46197 19.1546 7.56149 19.2661C7.66102 19.3776 7.78272 19.4671 7.9188 19.5289C8.05489 19.5907 8.20237 19.6234 8.35182 19.6249C8.41156 19.625 8.4712 19.6201 8.53014 19.6103C9.68385 19.4247 12.4768 18.7247 13.5656 16.2304C13.8737 15.5231 14.0099 14.6813 13.9717 13.7236C14.4876 13.3357 14.9751 12.9114 15.4305 12.4538C16.8669 11.0247 18.2033 8.93385 19.0962 6.71666C19.9852 4.50979 20.3199 2.48037 20.0153 1.15092ZM15.415 8.66529C15.062 9.01872 14.612 9.25949 14.1221 9.35714C13.6322 9.4548 13.1243 9.40494 12.6627 9.21389C12.2011 9.02283 11.8065 8.69916 11.5289 8.28383C11.2514 7.86849 11.1032 7.38015 11.1032 6.88059C11.1032 6.38102 11.2514 5.89268 11.5289 5.47735C11.8065 5.06201 12.2011 4.73834 12.6627 4.54729C13.1243 4.35623 13.6322 4.30638 14.1221 4.40403C14.612 4.50168 15.062 4.74245 15.415 5.09588C15.6517 5.32897 15.8396 5.60679 15.9679 5.91319C16.0961 6.21958 16.1622 6.54843 16.1622 6.88059C16.1622 7.21275 16.0961 7.54159 15.9679 7.84799C15.8396 8.15438 15.6517 8.4322 15.415 8.66529Z"
              fill="#7A5AF8"
            />
          </svg>
          <p className="text-2xl text-center font-Inter font-bold text-[#101828] ease-in duration-300 fade-in">
            Profitboost
          </p>
        </div>
        {/* <img src={logo} alt="logo" className='text-center w-20 mx-auto' /> */}
        <p className="text-3xl text-left font-Inter font-semibold text-[#101828] ease-in  duration-300 mt-2">
          {currentRoute === "signup" ? "Create an account" : "Log in to your account"}
        </p>
        <p className="font-Inter text-left text-[#101828] mt-1">
          {currentRoute === "signup"
            ? "Welcome! Please enter your details."
            : "Welcome back! Please enter your details."}
        </p>
        <form onSubmit={handleSubmit} className="px-7 sm:px-0">
          {currentRoute === "signup" && (
            <div className="mt-5">
              <label htmlFor="email" className="text-[#4E5674] font-[500] font-Inter">
                Name *
              </label>
              <div className="mb-4 mt-1 flex items-center rounded-lg border-2 py-2 px-3">
                <input
                  className="border-none outline-none bg-transparent w-full font-Inter"
                  type="text"
                  name="name"
                  id="text"
                  onChange={handleInputs}
                  placeholder="Enter your name"
                  required
                />
              </div>
            </div>
          )}
          <div className="mt-5">
            <label htmlFor="email" className="text-[#4E5674] font-[500] font-Inter">
              Email *
            </label>
            <div className="mb-4 mt-1 flex items-center rounded-lg border-2 py-2 px-3">
              <input
                className="border-none outline-none bg-transparent w-full font-Inter"
                type="text"
                name="email"
                id="email"
                onChange={handleInputs}
                placeholder="Enter your email"
                required
              />
            </div>
          </div>

          <div className="mt-4">
            <label htmlFor="password" className="text-[#4E5674] font-[500] font-Inter">
              Password *
            </label>
            <div className="mt-1 flex items-center rounded-lg border-2 py-2 px-3">
              <input
                className="border-none outline-none bg-transparent w-full font-Inter"
                type="password"
                name="password"
                id="password"
                minLength={8}
                onChange={handleInputs}
                placeholder={
                  currentRoute === "signup" ? "Create a password (min. 8 characters)" : "Enter your Password"
                }
                required
              />
            </div>
          </div>

          {currentRoute === "signup" ? (
            <div className=" flex my-4  flex-col">
              <div className="flex flex-col gap-y-2">
                <label className="text-[#4E5674] font-[500] font-Inter">
                  Card Details *
                </label>
                {/* <form id="payment-form" onSubmit={handleSubmit}> */}
                <div className="border border-gray-300 rounded-lg p-4">

                  <CardElement options={defaultCardElementOptions} />
                </div>

                {/* <button disabled={loading || !stripe || !elements} id="submit">
                    <span id="button-text">
                      {loading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                    </span>
                  </button> */}
                {/* Show any error or success messages */}
                {/* </form> */}
              </div>

              <div className="flex justify-start mt-6">




                <input type="checkbox" className="w-3 h-3 mt-1 text-[#031927] bg-gray-100 accent-gray-100  " required />
                <p className="text-[##2970FF] font-Plus text-sm font-[500] ml-2">
                  {" "}
                  I agree to the{" "}
                  <a href="#" className="font-normal text-sm text-primary ">
                    Terms & Conditions
                  </a>{" "}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex mt-1">
              {/* <div className='flex justify-end item-center mt-0.5'>
                                <input type="checkbox" className="w-3 h-3 mt-1 text-[#031927] bg-gray-100 accent-gray-100 " checked={rememberState} onChange={(e) => { setRememberState(e.target.checked) }} />
                                <p className="text-[##2970FF] font-Plus select-none text-sm font-[500] ml-2" onClick={() => { setRememberState(!rememberState) }} >Remember for 30 days</p>
                            </div> */}
              <p
                onClick={() => {
                  navigate("/forgot-password");
                }}
                className="font-[500] text-primary cursor-pointer font-Inter text-sm"
              >
                Forgot password
              </p>
            </div>
          )}

          {loading ? (
            <button
              disabled
              type="button"
              className="font-base mt-6 mb-2 block w-full rounded-lg bg-primary py-2.5 text-white"
            >
              <svg
                role="status"
                className="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Loading...
            </button>
          ) : (
            <button
              type="submit"
              className="font-semibold font-Inter text-sm mt-6 mb-2 block w-full rounded-lg transition duration-200 ease-in bg-primary hover:bg-primary py-2.5 text-white"
            >
              {currentRoute === "signup" ? "Get Access Now" : "Sign in"}
            </button>
          )}
        </form>
        {currentRoute === "signup" ? (
          <p className="text-[#475467] font-Inter text-md text-center mt-6">
            Already have an account?
            <Link className="text-primary ml-1 font-[500] font-Inter" to="/signin" replace>
              Login
            </Link>
          </p>
        ) : (
          <p className="text-[#475467] font-Inter text-md text-center mt-6">
            Don’t have an account?{" "}
            <Link className="text-primary ml-1 font-[500] font-Inter" to="/signup" replace>
              Sign Up
            </Link>
          </p>
        )}
      </div>


      {
        currentRoute === "signup" && (
          <div className="w-[85%] lg:w-[60%]">
            <SignupPricingTable setPlanSelected={setPlanSelected} planSelected={planSelected} />
          </div>

        )
      }
    </div>
  );
}

export default Authentication;
