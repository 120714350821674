import authenticatedInstance from "./AxiosInstance/authenticated";

export const redirectCustomerPortal = (customerId) => {
  return async (dispatch) => {
    try {
      const data = await authenticatedInstance.post(`/user/create-customer-portal`, {
        customerId: customerId,
      });
      console.log(data, "data");
      window.location.href = data.data.url;
      return true;
    } catch (e) {
      console.log(e, "Error in creating portal session");
    }
  };
};
