import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from "sonner";

export const fetchAcademyLists = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "SET_LOADING_TRUE" });
      const { data, status } = await authenticatedInstance.get("/academy");
      if (status === 200) {
        console.log(data);
        dispatch({
          type: "SET_VIDEOS",
          payload: data.VIDEO,
        });
        dispatch({
          type: "SET_PDFS",
          payload: data.PDF,
        });
        dispatch({
          type: "SET_DOCS",
          payload: data.DOCX,
        });
        dispatch({
          type: "SET_CSVS",
          payload: data.CSV,
        });
      }
      dispatch({ type: "SET_LOADING_FALSE" });
    } catch (e) {
      console.log(e, "error");
      toast.error("An Error Occured while fetching Academy Lists");
    }
  };
};
