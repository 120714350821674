import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Table } from "../components/Dashboard";

export const Tracking = (props) => {
  useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  return (
    <div className="py-4 w-full overflow-y-auto min-h-screen relative fade-in">
      <div className="flex-1 px-5 sm:px-5 w-full flex items-center justify-center sm:items-stretch sm:justify-start">
        <div className="flex items-baseline gap-x-2">
          <p className="text-2xl font-Inter font-semibold text-[#101828] ease-in duration-300 fade-in">Tracking</p>
          {/* <p className='border-[1.9px]  h-[25px] rounded-lg border-gray-400'></p> */}
          <p className="text-sm text-secondarySupport text-Inter">Track your favorite products here</p>
        </div>
      </div>

      <hr className="bg-gray-200 h-[1.15px] mt-3" />

      <div className="px-8 overflow-x-hidden mt-3 ">
        <Table />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Tracking);
