export const Tags = ({ product }) => {
  return (
    <div className="mt-4 px-2 space-y-2">
      <p className="text-Inter text-gray-800 font-semibold text-xl">Tags</p>
      <div className="mt-3 flex items-start gap-x-3">
        {product.tags.map((tag, index) => (
          <p
            key={index}
            className={`text-Inter text-sm text-${tag.tagColor}-600 bg-${tag.tagColor}-100 py-1 px-2.5 w-fit rounded- flex items-start gap-x-3md`}
          >
            {tag.tagEmoji + tag.tagName}
          </p>
        ))}

        <p className="text-Inter text-sm text-secondary border-[1px] border-slate-200 py-1 px-4 w-fit rounded-md font-semibold">
          {product.basicInfo.category}
        </p>
      </div>
    </div>
  );
};
