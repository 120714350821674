import React, { useEffect } from "react";
export function Modal(props) {
  const { handleClose } = props;

  useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  return (
    <div className="absolute md:fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm flex justify-center px-5  items-center w-full z-50">
      <div
        className={`bg-[#fff] rounded-md px-8 py-5 relative fade-in ${props.width ? props.width : `w-full sm:w-1/3`}`}
      >
        {props.children}
      </div>
    </div>
  );
}
