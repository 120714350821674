import { useEffect } from "react";
import Tracking from "./Tracking";
import Dashboard from "./Dashboard";
import { connect, useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Academy from "./Academy";
import Favourites from "./Favourites";
import ProductDetails from "./ProductDetails";
import { useLocation } from "react-router-dom";
import { getUserDetails } from "../redux/actions/auth";
import PricingPage from "./PricingPage";
import PaymentStatus from "../components/PaymentStatus";

export const Index = (props) => {
  const dispatch = useDispatch();
  const [sidebarClosingStatus, setSidebarClosingStatus] = useState(true);
  const location = useLocation();
  const currentRoute = location.pathname.slice(1);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    async function main() {
      try {
        setLoading(true);
        await dispatch(getUserDetails());
      } finally {
        setLoading(false);
      }
    }

    main();
  }, []);

  return (
    <div className="flex">
      <div
        id="sidebar"
        className={`invisible sm:visible transition-all duration-150 ease-in-out ${
          sidebarClosingStatus ? `w-[240px]` : `w-[80px]`
        }`}
      >
        <Sidebar setSidebarClosingStatus={setSidebarClosingStatus} sidebarClosingStatus={sidebarClosingStatus} />
      </div>
      <div
        className={`${sidebarClosingStatus ? `w-[calc(100%-240px)]` : `w-[calc(100%-80px)]`}`}
        style={{ transition: "all 0.15s ease-in-out" }}
      >
        {!currentRoute.includes("settings") && user && user.subscription && <PaymentStatus />}
        {currentRoute.includes("lists") && <Dashboard setSidebarClosingStatus={setSidebarClosingStatus} />}
        {currentRoute.includes("tracking") && <Tracking setSidebarClosingStatus={setSidebarClosingStatus} />}
        {currentRoute.includes("academy") && <Academy setSidebarClosingStatus={setSidebarClosingStatus} />}
        {currentRoute.includes("product") && <ProductDetails setSidebarClosingStatus={setSidebarClosingStatus} />}
        {currentRoute.includes("favourites") && <Favourites setSidebarClosingStatus={setSidebarClosingStatus} />}
        {currentRoute.includes("settings") && <PricingPage setSidebarClosingStatus={setSidebarClosingStatus} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
