import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Table } from "../components/Dashboard";
import authenticatedInstance from "../redux/actions/AxiosInstance/authenticated";
import { FavouriteTable } from "../components/Favourite";
import { Spin } from "antd";

export const Favourites = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [favouriteProducts, setFavouriteProducts] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    async function getFavourites() {
      try {
        setLoader(true);
        if (user) {
          const { data } = await authenticatedInstance.post("/product/getFavourites", { user_id: user.userId });
          console.log("resp", data);
          setFavouriteProducts(data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false);
      }
    }

    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
    getFavourites();
  }, [user]);

  return (
    <div className="py-4 w-full overflow-y-auto min-h-screen relative fade-in">
      <div className="flex-1 px-5 sm:px-5 w-full flex items-center justify-center sm:items-stretch sm:justify-start">
        <div className="flex items-baseline gap-x-2">
          <p className="text-2xl font-Inter font-semibold text-[#101828] ease-in duration-300 fade-in">Favorites</p>
          {/* <p className='border-[1.9px]  h-[25px] rounded-lg border-gray-400'></p> */}
          <p className="text-sm text-secondarySupport text-Inter">Track your favorite products here</p>
        </div>
      </div>

      <hr className="bg-gray-200 h-[1.15px] mt-3" />

       <Spin spinning={loader} size="large">
        <div className="px-8 overflow-x-hidden mt-3 ">
          <FavouriteTable favourites={favouriteProducts.favourites} setLoader={setLoader}/>
        </div>
      </Spin>
      
      
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Favourites);
