export const Variations = ({ variations }) => {
  console.log(variations);
  function addSpaceBetweenCapitalLetters(str) {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  }
  let dimensions = [];
  try {
    dimensions = [
      "ASIN",
      ...variations[0].attributes.map((attribute) => {
        return attribute.dimension.length > 1
          ? addSpaceBetweenCapitalLetters(attribute.dimension)
          : attribute.dimension;
      }),
    ];
  } catch (error) {
    console.log(error);
  }

  return (
    <div className="mt-5 pt-2 pb-4 px-5 border-[1px] rounded-lg space-y-3">
      <p className="text-Inter text-gray-800 font-semibold text-xl">Variations</p>
      <div className="mt-3">
        <table className="table-auto  border-collapse border-spacing-5">
          <thead>
            <tr>
              {dimensions.map((dimension, index) => (
                <th className="p-4 px-5 text-[15.4px]  text-Inter text-left" key={index}>
                  {dimension}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {variations.map((item, index) => (
              <tr key={index} className={`${index % 2 === 0 && `bg-[#F8F9FC]`}`}>
                <td className=" p-4 px-5 text-[15.4px]  text-Inter">{item.asin}</td>

                {item.attributes.map((attribute, key) => (
                  <td className=" p-4 px-5 text-[15.4px] text-Inter" key={key}>
                    {attribute.value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
